import React, { useState, useEffect } from "react";
import "./MusicController.css";

function MusicController() {
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState(new Set());
  const [url, setUrl] = useState("");
  const [selectAll, setSelectAll] = useState(false);

  const API_KEY = "57f749a75026dc31b4b8b016e462b5c9732c5cfe";

  useEffect(() => {
    fetchDevices();

    const intervalId = setInterval(fetchDevices, 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchDevices = async () => {
    const response = await fetch("https://music.picagroup.lt:3000/devices", {
      headers: {
        "API-Key": API_KEY,
      },
    });
    const data = await response.json();
    setDevices(data);
  };

  const handleUrlChange = async (deviceId, url) => {
    const response = await fetch(
      `https://music.picagroup.lt:3000/music/${deviceId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "API-Key": API_KEY,
        },
        body: JSON.stringify({ url }),
      }
    );

    if (response.ok) {
      fetchDevices();
    } else {
      alert("Failed to update music URL");
    }
  };

  const handleSoundChange = async (deviceId, sound) => {
    const response = await fetch(
      `https://music.picagroup.lt:3000/sound/${deviceId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "API-Key": API_KEY,
        },
        body: JSON.stringify({ sound }),
      }
    );

    if (response.ok) {
      alert("Sound updated successfully!");
      fetchDevices();
    } else {
      alert("Failed to update sound");
    }
  };

  const handleHourChange = async (deviceId, hourType, hour) => {
    const response = await fetch(
      `https://music.picagroup.lt:3000/${hourType}/${deviceId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "API-Key": API_KEY,
        },
        body: JSON.stringify({ [hourType]: hour }),
      }
    );

    if (response.ok) {
      alert("Hours updated successfully!");
      fetchDevices();
    } else {
      alert(`Failed to update ${hourType}`);
    }
  };

  const handleCheckboxChange = (deviceId) => {
    const updatedSelectedDevices = new Set(selectedDevices);

    if (selectAll) {
      setSelectAll(false);
      updatedSelectedDevices.clear();
    }

    if (updatedSelectedDevices.has(deviceId)) {
      updatedSelectedDevices.delete(deviceId);
    } else {
      updatedSelectedDevices.add(deviceId);
    }

    setSelectedDevices(updatedSelectedDevices);
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const updatedSelectedDevices = new Set();

    if (!selectAll) {
      devices.forEach((device) => updatedSelectedDevices.add(device.deviceId));
    }

    setSelectedDevices(updatedSelectedDevices);
  };

  const handleUpdateSelectedDevices = async () => {
    for (const deviceId of selectedDevices) {
      await new Promise((resolve) => {
        setTimeout(() => {
          handleUrlChange(deviceId, url);
          resolve();
        }, 1000);
      });
    }
  };

  const handleDelete = async (deviceId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this device?"
    );

    if (isConfirmed) {
      try {
        const response = await fetch(
          `https://music.picagroup.lt:3000/devices/${deviceId}`,
          {
            method: "DELETE",
            headers: {
              "API-Key": API_KEY,
            },
          }
        );

        if (response.ok) {
          fetchDevices();
          alert("Device deleted successfully");
        } else {
          alert("Failed to delete device");
        }
      } catch (error) {
        console.error(error);
        alert("An error occurred while deleting device");
      }
    }
  };

  return (
    <div className="MusicController">
      <h1>Music Controller</h1>
      <div className="input-container">
        <button onClick={handleUpdateSelectedDevices}>
          Update Selected Devices
        </button>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter URL"
        />
      </div>
      <div className="select-all-container">
        <input
          type="checkbox"
          checked={selectAll}
          onChange={handleSelectAllChange}
        />
        <label>Select All</label>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Select</th>
              <th>Device Name</th>
              <th>Status</th>
              <th>URL</th>
              <th>Sound</th>
              <th>Start hour</th>
              <th>End hour</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {devices.map((device) => (
              <tr key={device.deviceId}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedDevices.has(device.deviceId)}
                    onChange={() => handleCheckboxChange(device.deviceId)}
                  />
                </td>
                <td>{device.deviceId}</td>
                <td>{device.status}</td>
                <td>
                  <input
                    type="text"
                    value={device.url}
                    onChange={(e) =>
                      setDevices((prevDevices) =>
                        prevDevices.map((prevDevice) =>
                          prevDevice.deviceId === device.deviceId
                            ? { ...prevDevice, url: e.target.value }
                            : prevDevice
                        )
                      )
                    }
                  />
                  <button
                    onClick={() => handleUrlChange(device.deviceId, device.url)}
                  >
                    Update
                  </button>
                </td>
                <td>
                  <div className="sound-content">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={device.sound}
                      onChange={(e) =>
                        setDevices((prevDevices) =>
                          prevDevices.map((prevDevice) =>
                            prevDevice.deviceId === device.deviceId
                              ? { ...prevDevice, sound: e.target.value }
                              : prevDevice
                          )
                        )
                      }
                      onMouseUp={(e) =>
                        handleSoundChange(device.deviceId, e.target.value)
                      }
                    />
                    <span>{device.sound}</span>
                  </div>
                </td>
                <td>
                  <div className="hour-control">
                    <input
                      className="hour-input"
                      type="number"
                      min="0"
                      max="23"
                      value={device.startHour}
                      onChange={(e) =>
                        setDevices((prevDevices) =>
                          prevDevices.map((prevDevice) =>
                            prevDevice.deviceId === device.deviceId
                              ? { ...prevDevice, startHour: e.target.value }
                              : prevDevice
                          )
                        )
                      }
                    />
                    <button
                      onClick={() =>
                        handleHourChange(
                          device.deviceId,
                          "startHour",
                          device.startHour
                        )
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>
                <td>
                  <div className="hour-control">
                    <input
                      className="hour-input"
                      type="number"
                      min="0"
                      max="23"
                      value={device.endHour}
                      onChange={(e) =>
                        setDevices((prevDevices) =>
                          prevDevices.map((prevDevice) =>
                            prevDevice.deviceId === device.deviceId
                              ? { ...prevDevice, endHour: e.target.value }
                              : prevDevice
                          )
                        )
                      }
                    />
                    <button
                      onClick={() =>
                        handleHourChange(
                          device.deviceId,
                          "endHour",
                          device.endHour
                        )
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>
                <td>
                  <button
                    className="delete-button"
                    onClick={() => handleDelete(device.deviceId)}
                  >
                    ❌
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MusicController;
